import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { URL_EXCEPTION_REGEX } from '../Variables';
import { addImgSourceParam } from '../components/Data';
import { topicsShortcutFunc } from '../Variables';

const useSinglePetition = (url, lang, nidDigits) => {
  const navigate = useNavigate();
  const [petitionData, setPetitionData] = useState([]);
  const [petitionBody, setPetitionBody] = useState('');
  const [featured_video_id, setFeaturedVideoId] = useState('');
  const [victoryPetition, setVictoryPetition] = useState(true);

  const checkIfResponseIsEmpty = (res, disabled) => {
    if (!res || disabled) {
      navigate('/notfound', { replace: true });
    }
  };

  const openLinkInNewTab = (_petition_body) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(_petition_body, 'text/html');
    const links = htmlDoc.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute('target', '_blank');
    }
    return htmlDoc.body.innerHTML;
}

  const checkIfM8IsPresent = (title, after_sign) => {
    const PARAMS = new URLSearchParams(window.location.search);
    const m = PARAMS.get('m');
    const _utm_campaign = PARAMS.get('utm_campaign');
    if (m === '8') {
      return navigate(
        `/LP2-share?m=8&nid=${nidDigits}&title=${title
          .replace(URL_EXCEPTION_REGEX, '-')
          .toLowerCase()}&utm_campaign=${
          _utm_campaign ? _utm_campaign : ''
        }&list=${lang}&flowType=${after_sign}${addImgSourceParam()}`
      );
    }
  };

  const extractVideoId = (url) => {
    try {
      const videoId = new URL(url).searchParams.get('v');
      return videoId;
    } catch(err) {
      console.error("Invalid video URL:", url);
      return null;
    }
  }

  const fetchPetitionData = async () => {
    try {
      const res = await axios.get(url);
      const urlParams = window.location.search;
      if (
        !window.location.pathname.includes(lang) ||
        res.data[0].segment.code !== lang
      ) {
        window.location.href = `/${
          res?.data[0]?.segment?.code
        }/${topicsShortcutFunc(res?.data[0]?.topic?.code)}/${nidDigits}${urlParams}`;
      }
      checkIfResponseIsEmpty(res.data[0], res.data[0].disabled);
      setVictoryPetition(res.data[0].status);
      setPetitionData(res.data[0]);
      setPetitionBody(openLinkInNewTab(res.data[0].body));

      const videoUrl = res.data[0].featured_video;
      if(videoUrl && videoUrl.startsWith("http")) {
        setFeaturedVideoId(extractVideoId(videoUrl));
      } else {
        console.warn("Invalid video URL:", videoUrl);
      }
      
      checkIfM8IsPresent(res.data[0].title, res.data[0].after_sign);
    } catch (err) {
      if (err?.res?.status === 500) {
        navigate('/notfound', { replace: true });
      }
      console.log(err.message, err);
    }
  };

  useEffect(() => {
    fetchPetitionData();
  }, []);
  return { petitionData, victoryPetition, petitionBody, featured_video_id };
};

export default useSinglePetition;
